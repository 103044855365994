import React from 'react';
import styled from "styled-components";

export const Seal = () => {
    return (
        <Wrap>
            <svg width="192" height="192" viewBox="0 0 192 192">

           
<path fillRule="evenodd" clipRule="evenodd" d="M96 1.97938C44.0738 1.97938 1.97938 44.0738 1.97938 96C1.97938 147.926 44.0738 190.021 96 190.021C147.926 190.021 190.021 147.926 190.021 96C190.021 44.0738 147.926 1.97938 96 1.97938ZM0 96C0 42.9807 42.9807 0 96 0C149.019 0 192 42.9807 192 96C192 149.019 149.019 192 96 192C42.9807 192 0 149.019 0 96Z" />
<path d="M118.716 16.1117L123.405 17.5556L123.064 18.6822L119.779 17.6746L119.089 19.9278L121.675 20.7211L121.342 21.816L118.756 21.0226L117.804 24.1247L116.392 23.6883L118.716 16.1117Z" />
<path d="M126.728 18.8171L128.514 19.5391L127.958 27.8694L126.546 27.2903L126.697 25.2355L123.888 24.1009L122.579 25.6877L121.294 25.1641L126.728 18.8171ZM124.618 23.2362L126.784 24.1168L127.086 20.269L124.618 23.2362Z" />
<path d="M130.22 24.6087L130.26 24.5294C131.378 22.427 133.608 21.7209 135.551 22.7602C137.154 23.6091 137.979 25.0768 137.257 26.8063L135.932 26.1082C136.281 25.1006 136.011 24.3469 135.004 23.8074C133.774 23.1489 132.457 23.6884 131.624 25.2513L131.584 25.3307C130.751 26.9095 131.005 28.2344 132.298 28.9246C133.266 29.4403 134.21 29.3134 134.877 28.3931L136.154 29.0674C135.051 30.6462 133.409 30.8604 131.743 29.9798C129.553 28.8135 129.077 26.7667 130.22 24.6087Z" />
<path d="M140.406 25.6639L144.531 28.409L143.889 29.3769L140.985 27.449L139.779 29.2579L142.104 30.8049L141.493 31.7252L139.168 30.1782L137.883 32.1061L140.938 34.1371L140.295 35.105L136.011 32.2568L140.406 25.6639Z" />
<path d="M146.475 29.8291L148.689 31.6142C149.958 32.6376 150.315 33.7245 149.411 34.8511L149.379 34.8828C148.887 35.4937 148.221 35.7555 147.348 35.4065C148.038 36.2633 148.07 37.0408 147.404 37.8579L147.38 37.8897C146.452 39.0401 145.23 39.0004 143.873 37.9135L141.501 36.0015L146.475 29.8291ZM144.397 36.9852C145.166 37.6041 145.801 37.5485 146.317 36.9059L146.348 36.8742C146.864 36.2395 146.769 35.6286 145.92 34.9463L144.881 34.1133L143.286 36.0887L144.397 36.9852ZM146.46 34.0974C147.221 34.7083 147.737 34.6686 148.245 34.0419L148.276 34.0101C148.752 33.423 148.617 32.8994 147.88 32.3123L146.951 31.5586L145.515 33.3358L146.46 34.0974Z" />
<path d="M149.569 37.5167L149.633 37.4532C151.275 35.8189 153.663 35.7792 155.321 37.4453C156.987 39.1193 156.956 41.4518 155.305 43.094L155.25 43.1575C153.608 44.7918 151.259 44.9029 149.561 43.1972C147.84 41.4835 147.951 39.1352 149.569 37.5167ZM154.163 42.0865L154.226 42.023C155.377 40.8726 155.591 39.3732 154.496 38.2783C153.401 37.1835 151.942 37.3342 150.736 38.5401L150.672 38.6036C149.45 39.8254 149.403 41.3645 150.418 42.38C151.473 43.4431 152.949 43.3003 154.163 42.0865Z" />
<path d="M156.249 44.3238L156.321 44.2683C158.146 42.8323 160.518 43.0783 161.97 44.9268C163.429 46.7833 163.12 49.0999 161.287 50.5359L161.216 50.5914C159.391 52.0274 157.043 51.8529 155.559 49.9647C154.06 48.0606 154.448 45.736 156.249 44.3238ZM160.264 49.4014L160.335 49.3458C161.62 48.3383 162.001 46.8785 161.049 45.6567C160.097 44.4349 158.622 44.419 157.281 45.4742L157.209 45.5298C155.853 46.6008 155.623 48.1161 156.511 49.2506C157.431 50.4327 158.915 50.4645 160.264 49.4014Z" />
<path d="M165.644 50.1154L166.421 51.3689L163.367 53.265L168.032 53.9394L168.873 55.2881L163.914 54.5027L162.217 59.6199L161.296 58.1442L162.827 53.5982L159.686 55.5578L158.908 54.3043L165.644 50.1154Z" />
<path d="M171.34 60.0642L172.387 62.3888C173.117 64.0072 172.871 65.3956 171.356 66.0779L171.316 66.0938C169.785 66.7761 168.619 65.9827 167.92 64.4356L167.468 63.4201L164.715 64.6578L164.112 63.3091L171.34 60.0642ZM168.872 63.9676C169.269 64.8561 169.92 65.1417 170.721 64.7768L170.761 64.7609C171.618 64.3722 171.729 63.7216 171.34 62.8569L170.904 61.8731L168.436 62.9838L168.872 63.9676Z" />
<path d="M174.355 67.0696L175.212 69.5845C175.767 71.2347 175.489 72.6152 173.958 73.1309L173.918 73.1468C172.744 73.5434 171.895 73.0119 171.34 72.163L168.809 75.3682L168.294 73.8608L170.658 70.8698L170.356 69.9812L167.326 71.0126L166.85 69.6163L174.355 67.0696ZM171.729 70.7667C172.046 71.7028 172.609 72.0598 173.411 71.7901L173.45 71.7742C174.307 71.4807 174.426 70.846 174.125 69.9574L173.744 68.8309L171.348 69.6401L171.729 70.7667Z" />
<path d="M172.854 75.2412L172.942 75.2254C175.203 74.7255 177.242 75.9791 177.749 78.2719C178.257 80.5806 176.98 82.5322 174.703 83.0321L174.616 83.0479C172.355 83.5478 170.316 82.3815 169.792 80.0252C169.268 77.661 170.625 75.7331 172.854 75.2412ZM174.274 81.5564L174.362 81.5405C175.948 81.1915 176.932 80.0411 176.599 78.5258C176.266 77.0104 174.949 76.3599 173.283 76.7328L173.195 76.7486C171.506 77.1215 170.641 78.3909 170.95 79.8031C171.276 81.2549 172.6 81.9293 174.274 81.5564Z" />
<path d="M178.837 84.5474L179.091 86.9433C179.385 89.6884 177.956 91.1878 175.664 91.4338L175.568 91.4417C173.276 91.6797 171.498 90.5214 171.213 87.7526L170.959 85.3804L178.837 84.5474ZM172.355 87.586C172.538 89.3472 173.648 90.093 175.386 89.9105L175.473 89.9026C177.171 89.7201 178.115 88.8474 177.917 86.9909L177.83 86.142L172.26 86.7291L172.355 87.586Z" />
<path d="M174.759 93.2585L179.67 93.3378L179.646 94.8214L174.846 94.7421C173.394 94.7183 172.784 95.1705 172.768 96.3526C172.752 97.495 173.252 98.0821 174.894 98.1059L179.599 98.1853L179.575 99.6689L174.751 99.5895C172.68 99.5578 171.554 98.4153 171.586 96.3209C171.609 94.3375 172.712 93.2267 174.759 93.2585Z" />
<path d="M175.528 101.454L175.616 101.462C177.988 101.724 179.464 103.532 179.226 105.73C179.027 107.539 177.972 108.84 176.1 108.816L176.258 107.325C177.321 107.277 177.924 106.746 178.051 105.611C178.202 104.223 177.218 103.199 175.457 103.009L175.37 103.001C173.593 102.811 172.458 103.54 172.299 105C172.18 106.095 172.649 106.92 173.743 107.198L173.585 108.634C171.712 108.197 170.903 106.746 171.101 104.873C171.371 102.398 173.101 101.192 175.528 101.454Z" />
<path d="M176.877 112.521L177.329 110.363L178.472 110.601L177.266 116.377L176.124 116.139L176.576 113.981L169.951 112.601L170.253 111.149L176.877 112.521Z" />
<path d="M175.814 121.534L175.005 123.803C174.077 126.405 172.141 127.135 169.967 126.366L169.872 126.334C167.698 125.556 166.603 123.74 167.539 121.121L168.341 118.876L175.814 121.534ZM168.658 121.463C168.063 123.137 168.737 124.279 170.388 124.874L170.475 124.906C172.085 125.477 173.315 125.096 173.942 123.343L174.228 122.542L168.952 120.661L168.658 121.463Z" />
<path d="M172.688 129.777L170.57 134.26L169.515 133.76L170.998 130.61L169.031 129.682L167.841 132.205L166.841 131.729L168.031 129.206L165.936 128.214L164.374 131.53L163.318 131.031L165.516 126.381L172.688 129.777Z" />
<path d="M165.001 133.173L164.279 134.395C163.541 134.077 162.803 134.014 162.121 135.172C161.677 135.926 161.78 136.711 162.414 137.084C163.041 137.457 163.47 137.282 164.303 136.283C165.485 134.775 166.437 134.299 167.714 135.053C168.833 135.711 169.11 137.163 168.27 138.583C167.405 140.051 166.215 140.535 164.826 139.9L165.517 138.734C166.31 139.036 166.857 138.798 167.317 138.02C167.77 137.243 167.635 136.624 167.111 136.314C166.556 135.989 166.143 136.053 165.279 137.14C164.112 138.615 163.168 139.178 161.796 138.369C160.614 137.671 160.272 136.124 161.169 134.617C162.264 132.744 163.652 132.514 165.001 133.173Z" />
<path d="M165.342 143.05L164.501 144.272L157.972 139.773L158.813 138.552L165.342 143.05Z" />
<path d="M160.105 143.645L160.177 143.701C162.002 145.2 162.271 147.533 160.804 149.326C159.733 150.643 158.17 151.317 156.575 150.278L157.511 149.135C158.439 149.667 159.241 149.405 159.891 148.604C160.875 147.406 160.55 145.978 159.201 144.875L159.13 144.819C157.813 143.748 156.416 143.613 155.361 144.907C154.417 146.057 154.726 147.096 155.551 147.826L156.813 146.279L157.67 146.977L155.488 149.651L154.925 149.191C153.227 147.802 153.068 145.89 154.48 144.161C156.091 142.193 158.289 142.161 160.105 143.645Z" />
<path d="M156.798 153.776L155.536 155.006L149.142 153.229L153.148 157.331L152.188 158.267L146.65 152.586L147.745 151.515L154.544 153.372L150.3 149.016L151.26 148.08L156.798 153.776Z" />
<path d="M150.109 160.044L146.237 163.138L145.507 162.226L148.228 160.052L146.872 158.354L144.69 160.099L144 159.235L146.181 157.489L144.737 155.68L141.873 157.973L141.144 157.061L145.158 153.848L150.109 160.044Z" />
<path d="M144.301 164.542L142.08 166.002C140.628 166.962 139.216 167.033 138.327 165.685L138.304 165.645C137.621 164.606 137.923 163.654 138.605 162.9L134.86 161.266L136.193 160.385L139.684 161.924L140.469 161.409L138.708 158.735L139.938 157.926L144.301 164.542ZM140.065 162.94C139.24 163.479 139.033 164.122 139.502 164.828L139.525 164.868C140.025 165.629 140.668 165.574 141.453 165.058L142.445 164.407L141.057 162.297L140.065 162.94Z" />
<path d="M132.695 171.357L130.949 172.182L125.197 166.129L126.578 165.478L127.99 166.986L130.727 165.693L130.449 163.654L131.703 163.059L132.695 171.357ZM130.878 166.811L128.759 167.811L131.386 170.643L130.878 166.811Z" />
<path d="M126.602 174.055L124.198 174.912C122.524 175.507 121.168 175.158 120.605 173.587L120.589 173.547C120.025 171.968 120.914 170.865 122.517 170.294L123.564 169.921L122.556 167.081L123.945 166.589L126.602 174.055ZM122.913 171.286C121.993 171.611 121.66 172.238 121.953 173.063L121.969 173.103C122.286 173.991 122.929 174.15 123.818 173.833L124.833 173.476L123.929 170.929L122.913 171.286Z" />
<path d="M119.398 176.49L116.931 177.125C115.209 177.561 113.892 177.093 113.48 175.483L113.472 175.443C113.059 173.825 114.043 172.801 115.685 172.381L116.756 172.103L116.011 169.183L117.439 168.818L119.398 176.49ZM115.971 173.396C115.027 173.634 114.638 174.229 114.86 175.078L114.868 175.118C115.098 176.03 115.733 176.244 116.645 176.014L117.685 175.745L117.01 173.126L115.971 173.396Z" />
<path d="M111.988 178.252L110.536 178.49L109.442 171.825L106.102 172.373L105.911 171.215L110.703 170.429L111.988 178.252Z" />
<path d="M105.149 179.235L103.674 179.378L102.912 171.484L104.388 171.341L105.149 179.235Z" />
<path d="M101.317 175.61V175.697C101.357 178.077 99.7465 179.775 97.541 179.815C95.7242 179.846 94.2961 178.958 94.0898 177.101L95.5893 177.077C95.7718 178.125 96.3747 178.664 97.5172 178.64C98.9135 178.617 99.8021 177.514 99.7703 175.737V175.649C99.7386 173.864 98.8659 172.825 97.4061 172.849C96.3112 172.865 95.5417 173.436 95.4068 174.562L93.9629 174.586C94.1612 172.674 95.502 171.683 97.3823 171.651C99.8655 171.603 101.278 173.166 101.317 175.61Z" />
<path d="M89.9961 179.458L88.0762 179.291L86.1562 171.175L87.6795 171.31L88.1397 173.317L91.1544 173.579L91.9399 171.675L93.3203 171.794L89.9961 179.458ZM90.7181 174.618L88.3856 174.42L89.2266 178.188L90.7181 174.618Z" />
<path d="M82.8554 177.435L85.0292 177.815L84.8309 178.966L79.0234 177.958L79.2218 176.808L81.3956 177.189L82.5539 170.524L84.0137 170.778L82.8554 177.435Z" />
<path d="M77.0728 177.458L75.6289 177.101L77.525 169.406L78.969 169.763L77.0728 177.458Z" />
<path d="M74.6439 172.785L74.6122 172.873C73.8665 175.062 71.7402 176.157 69.5188 175.404C67.2815 174.642 66.2739 172.539 67.0276 170.334L67.0594 170.247C67.8051 168.057 69.86 166.915 72.1369 167.684C74.4377 168.47 75.3818 170.627 74.6439 172.785ZM68.5112 170.731L68.4795 170.818C67.9559 172.365 68.4319 173.793 69.8996 174.293C71.3674 174.793 72.605 174.007 73.1524 172.389L73.1842 172.301C73.7395 170.667 73.1048 169.263 71.7402 168.803C70.3201 168.327 69.0587 169.104 68.5112 170.731Z" />
<path d="M63.5928 173.087L61.9982 172.333L61.4349 165.724L58.9834 170.905L57.7695 170.334L61.1651 163.17L62.5456 163.828L63.1723 170.85L65.7746 165.359L66.9884 165.931L63.5928 173.087Z" />
<path d="M55.4051 163.717C54.9926 164.376 54.1278 164.59 53.4455 164.154C52.7553 163.717 52.5887 162.852 53.0091 162.194C53.4296 161.528 54.2706 161.313 54.9688 161.758C55.651 162.194 55.8256 163.051 55.4051 163.717Z" />
<path d="M51.3433 157.085L50.6293 157.989C50.4786 157.727 50.3199 157.489 49.955 157.204C49.3917 156.759 48.7887 156.767 48.1857 157.537L44.7981 161.853L43.6318 160.933L47.0433 156.593C48.1064 155.244 49.3679 155.236 50.5182 156.141C51.026 156.537 51.2243 156.823 51.3433 157.085Z" />
<path d="M45.0358 155.768L41.6799 159.354L40.593 158.338L43.8775 154.839C44.8692 153.776 44.9406 153.023 44.0838 152.221C43.2507 151.444 42.4812 151.42 41.3625 152.618L38.1494 156.045L37.0625 155.022L40.355 151.507C41.7751 149.992 43.3697 149.928 44.9009 151.357C46.3449 152.713 46.4321 154.276 45.0358 155.768Z" />
<path d="M39.6732 149.865L38.737 148.794C39.2765 148.191 39.5621 147.517 38.6815 146.501C38.1023 145.843 37.3328 145.692 36.7774 146.176C36.23 146.66 36.2538 147.112 36.944 148.223C37.9992 149.817 38.1499 150.873 37.0313 151.849C36.0554 152.705 34.5877 152.507 33.5008 151.261C32.3822 149.976 32.2949 148.699 33.3342 147.58L34.2228 148.596C33.6912 149.254 33.7388 149.841 34.3338 150.532C34.9209 151.206 35.5556 151.277 36.0158 150.873C36.4997 150.452 36.5711 150.04 35.8174 148.873C34.7861 147.303 34.556 146.224 35.746 145.184C36.7853 144.28 38.3562 144.454 39.5066 145.771C40.9346 147.406 40.7284 148.794 39.6732 149.865Z" />
<path d="M30.2878 145.264L31.589 147.049L30.6449 147.731L27.1699 142.955L28.114 142.273L29.4151 144.058L34.8894 140.075L35.7621 141.273L30.2878 145.264Z" />
<path d="M26.1147 141.265L25.3213 140.004L32.0411 135.791L32.8345 137.052L26.1147 141.265Z" />
<path d="M23.8922 137.703L23.0751 136.14L26.6135 130.531L21.528 133.181L20.9092 131.991L27.9384 128.325L28.6445 129.682L24.9077 135.656L30.2947 132.847L30.9135 134.037L23.8922 137.703Z" />
<path d="M18.5534 126.461L17.6727 123.946C17.1015 122.303 17.3633 120.915 18.8945 120.384L18.9342 120.368C20.1084 119.955 20.9573 120.487 21.5285 121.328L24.0276 118.091L24.5512 119.598L22.2108 122.613L22.5202 123.501L25.5429 122.446L26.0269 123.835L18.5534 126.461ZM21.1477 122.74C20.8224 121.812 20.2512 121.455 19.4578 121.732L19.4181 121.748C18.5613 122.05 18.4502 122.684 18.7596 123.573L19.1484 124.691L21.5364 123.858L21.1477 122.74Z" />
<path d="M15.9592 118.678L15.626 117.242L18.9105 116.472L18.1013 113.005L14.8167 113.775L14.4756 112.331L22.195 110.522L22.5362 111.966L19.2516 112.736L20.0609 116.203L23.3454 115.433L23.6786 116.869L15.9592 118.678Z" />
<path d="M18.0143 109.57L17.927 109.578C15.6263 109.84 13.7302 108.388 13.4604 106.055C13.1907 103.707 14.6663 101.898 16.9829 101.628L17.0702 101.621C19.371 101.359 21.283 102.731 21.5607 105.119C21.8304 107.531 20.2833 109.308 18.0143 109.57ZM17.2527 103.136L17.1654 103.144C15.547 103.334 14.4521 104.373 14.6267 105.913C14.8012 107.452 16.0468 108.237 17.7446 108.039L17.8318 108.031C19.5534 107.833 20.5452 106.658 20.3785 105.222C20.204 103.747 18.9584 102.938 17.2527 103.136Z" />
<path d="M13.0317 98.1457L13 96.2099L20.886 93.4648L20.9098 94.996L18.9582 95.6625L19.0058 98.6931L20.9812 99.2802L21.005 100.669L13.0317 98.1457ZM17.9268 98.3599L17.8871 96.0195L14.2218 97.2413L17.9268 98.3599Z" />
<path d="M13.1035 91.4576L13.3336 89.0616C13.5954 86.3086 15.2932 85.1265 17.586 85.3487L17.6892 85.3566C19.982 85.5787 21.4894 87.0703 21.2276 89.8391L20.9975 92.2113L13.1035 91.4576ZM20.0613 89.7677C20.2279 87.9985 19.2918 87.0544 17.5543 86.8878L17.467 86.8799C15.7692 86.7212 14.6664 87.3955 14.4919 89.2441L14.4126 90.093L19.9899 90.6245L20.0613 89.7677Z" />
<path d="M14.1025 82.7305L15.0466 77.8672L16.1891 78.0893L15.5306 81.5087L17.6647 81.9213L18.1963 79.1762L19.2832 79.3904L18.7517 82.1355L21.0286 82.5798L21.7268 78.9779L22.8692 79.2L21.8934 84.2458L14.1025 82.7305Z" />
<path d="M20.7188 77.542L21.1155 76.1774C21.9089 76.3043 22.6388 76.1853 23.0117 74.8921C23.2576 74.0512 22.9641 73.3213 22.258 73.115C21.5519 72.9087 21.1869 73.1864 20.6236 74.3606C19.8461 76.106 19.0448 76.8121 17.6168 76.3916C16.3712 76.0266 15.7444 74.6938 16.2046 73.107C16.6806 71.4648 17.712 70.7032 19.2114 70.9808L18.8306 72.2819C17.9897 72.1867 17.5216 72.5438 17.2677 73.4165C17.0138 74.2812 17.2994 74.8445 17.8865 75.0191C18.5053 75.2015 18.8862 75.0349 19.4653 73.7655C20.2269 72.0519 21.0124 71.2744 22.5356 71.7187C23.8526 72.0995 24.5587 73.5196 24.0748 75.2015C23.4718 77.2801 22.1866 77.8514 20.7188 77.542Z" />
<path d="M23.171 67.1013C22.457 66.7998 22.1 65.9906 22.4173 65.2448C22.7347 64.4911 23.5598 64.1897 24.2738 64.4911C24.9957 64.7926 25.3528 65.5939 25.0275 66.3476C24.7181 67.0934 23.9009 67.4107 23.171 67.1013Z" />
<path d="M22.457 57.7237L23.655 55.6372C25.0275 53.2412 27.0585 52.8604 29.0578 54.0028L29.1451 54.0504C31.1444 55.1929 31.906 57.1763 30.5255 59.5881L29.3434 61.6588L22.457 57.7237ZM29.4862 59.0486C30.3669 57.5095 29.9067 56.2639 28.3835 55.3912L28.3041 55.3436C26.8205 54.4947 25.5432 54.6534 24.615 56.2718L24.1945 57.0097L29.0578 59.7944L29.4862 59.0486Z" />
<path d="M26.9717 50.1709L29.8437 46.1327L30.7957 46.807L28.7805 49.6473L30.5498 50.9008L32.1682 48.6239L33.0727 49.2665L31.4542 51.5434L33.3424 52.8842L35.4686 49.8932L36.4206 50.5676L33.4455 54.7645L26.9717 50.1709Z" />
<path d="M34.0075 47.2275L33.944 47.1719C32.1431 45.609 31.9606 43.2845 33.4045 41.6105C34.5946 40.2379 36.2051 39.7699 37.7284 40.8568L36.7446 41.9913C35.8401 41.428 35.0468 41.5153 34.2931 42.38C33.3807 43.4352 33.6029 44.8315 34.9436 45.9978L35.0071 46.0533C36.3558 47.2196 37.7125 47.2672 38.6725 46.1644C39.3944 45.3393 39.4817 44.3872 38.7359 43.5383L39.68 42.4514C40.9653 43.8795 40.8145 45.5297 39.5769 46.9577C37.9584 48.8142 35.8481 48.8221 34.0075 47.2275Z" />
<path d="M37.3174 37.5405L40.9193 34.129L41.7206 34.9779L39.1897 37.3739L40.6812 38.9527L42.7123 37.0327L43.4739 37.842L41.4429 39.7619L43.0375 41.4438L45.7032 38.9209L46.5045 39.7698L42.7678 43.3083L37.3174 37.5405Z" />
<path d="M42.7432 32.4788L44.314 31.2888L49.2487 34.3353L47.5986 28.7976L49.1535 27.6155L53.9455 33.9227L52.7713 34.8113L49.1535 30.0432L50.8989 36.2394L50.5181 36.525L44.9805 33.2166L48.5982 37.9848L47.543 38.7861L42.7432 32.4788Z" />
<path d="M51.4863 26.1239L53.922 24.6721C55.3262 23.8391 56.4687 23.8787 57.2065 25.1164L57.2303 25.156C57.6349 25.8304 57.6428 26.5444 57.0161 27.2426C58.0554 26.8935 58.8012 27.1395 59.3327 28.0439L59.3644 28.0836C60.1181 29.3529 59.658 30.4874 58.1665 31.376L55.5484 32.931L51.4863 26.1239ZM55.477 27.6313C56.3179 27.1315 56.4607 26.6317 56.0482 25.9415L56.0244 25.9018C55.6356 25.2512 55.1041 25.1957 54.2948 25.6797L53.2635 26.2906L54.4297 28.2502L55.477 27.6313ZM57.4683 30.5589C58.3172 30.059 58.4838 29.4402 58.0633 28.7341L58.0395 28.6944C57.619 27.9884 57.0161 27.8693 56.0878 28.4247L54.9454 29.107L56.2465 31.2887L57.4683 30.5589Z" />
<path d="M58.4355 22.1414L62.9101 20.0072L63.4099 21.0624L60.2682 22.5618L61.2044 24.5214L63.7273 23.3235L64.2033 24.3231L61.6804 25.5211L62.68 27.6156L65.9963 26.0368L66.4961 27.0919L61.847 29.3054L58.4355 22.1414Z" />
<path d="M65.1162 19.0868L67.6153 18.1745C69.2496 17.5794 70.638 17.8254 71.1934 19.3407L71.2092 19.3804C71.6377 20.5466 71.122 21.4034 70.2889 21.9826L73.5576 24.4341L72.0581 24.9815L69.0116 22.6887L68.1231 23.014L69.2258 26.0208L67.8375 26.5286L65.1162 19.0868ZM68.8768 21.6177C69.805 21.2765 70.1461 20.7053 69.8605 19.9119L69.8447 19.8723C69.5352 19.0154 68.8926 18.9202 68.012 19.2455L66.9013 19.6501L67.774 22.0223L68.8768 21.6177Z" />
<path d="M76.9216 23.1965L79.1589 19.4677C79.7222 18.5236 80.0157 17.7461 79.8491 17.0083C79.6904 16.2863 79.1747 15.9214 78.429 16.088C77.6594 16.2546 77.2707 16.81 77.3817 17.9286L76.0727 18.2221C75.795 16.5085 76.5963 15.3105 78.2306 14.9535C79.8332 14.5965 80.9915 15.3105 81.293 16.6751C81.5152 17.6827 81.1185 18.6902 80.3886 19.7851L79.0399 21.8399L82.3323 21.11L82.5783 22.2286L76.9851 23.4663L76.9216 23.1965Z" />
<path d="M83.2358 18.2697L83.1882 17.873C82.8947 15.4849 84.0371 13.8585 86.0126 13.6126C88.004 13.3667 89.4796 14.6598 89.7732 17.0241L89.8208 17.4208C90.1143 19.785 89.0671 21.4431 87.0043 21.697C85.0051 21.9509 83.5294 20.6339 83.2358 18.2697ZM88.361 17.6032L88.3134 17.2145C88.1071 15.5405 87.3455 14.6043 86.1634 14.755C84.9733 14.8979 84.4418 15.9292 84.656 17.6746L84.7036 18.0713C84.9257 19.8564 85.7191 20.6735 86.8933 20.5307C88.1071 20.38 88.5752 19.3883 88.361 17.6032Z" />
<path d="M91.1465 20.864L94.0581 17.6191C94.788 16.802 95.2244 16.088 95.2085 15.3343C95.1847 14.5885 94.7563 14.1363 93.9867 14.1601C93.2013 14.1839 92.7094 14.652 92.6142 15.7706L91.2576 15.8103C91.3131 14.0807 92.3286 13.0494 94.0026 13.0018C95.6449 12.9542 96.6445 13.8745 96.6842 15.2708C96.7159 16.3022 96.1288 17.2145 95.2085 18.1507L93.4948 19.9199L96.8666 19.8247L96.8984 20.9671L91.1623 21.1258L91.1465 20.864Z" />
<path d="M98.2705 17.2145L98.2943 16.8178C98.4529 14.4139 99.8731 13.0335 101.864 13.1604C103.864 13.2953 105.078 14.8344 104.919 17.2145L104.895 17.6112C104.736 19.9913 103.404 21.4273 101.333 21.2924C99.3256 21.1654 98.1197 19.5946 98.2705 17.2145ZM103.427 17.5239L103.451 17.1352C103.562 15.4532 102.983 14.3901 101.801 14.3187C100.603 14.2394 99.8889 15.1517 99.7779 16.9051L99.7541 17.3018C99.6351 19.0948 100.262 20.0468 101.444 20.1261C102.666 20.2055 103.316 19.3169 103.427 17.5239Z" />
<path d="M109.465 18.7218C109.608 17.9602 110.322 17.4366 111.123 17.5873C111.933 17.7381 112.401 18.4759 112.258 19.2454C112.115 20.0229 111.409 20.5307 110.6 20.38C109.806 20.2292 109.323 19.4914 109.465 18.7218Z" />



            </svg>

        </Wrap>
    );
}

const Wrap = styled.div`
    width: 192px;
    height: 192px;
    position: absolute;
    right: -1em;
    top: calc(-8em + 40px);
    animation: rotation 60s infinite linear;
    svg {
        fill: #FFFFFF;
    }
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
    @media (min-width: 768px) {
        right: 2em;
    }
`;




